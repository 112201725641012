/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    state?: AuditLogStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof AuditLog
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    requestTo?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditLog
     */
    accepted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AuditLog
     */
    deadline?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AuditLog
     */
    onlineMeeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    nameJa: string;
}

/**
* @export
* @enum {string}
*/
export enum AuditLogStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    PrivateConsentAcceptedForMaster = 'PrivateConsentAcceptedForMaster',
    PrivateConsentAcceptedForBachelor = 'PrivateConsentAcceptedForBachelor',
    PrivateConsentRejected = 'PrivateConsentRejected',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    ApprovalNumberNotifiedForBachelor = 'ApprovalNumberNotifiedForBachelor',
    ApprovalNumberNotifiedForMaster = 'ApprovalNumberNotifiedForMaster',
    ApprovalNumberNotifiedRejected = 'ApprovalNumberNotifiedRejected',
    TerminatedOthers = 'TerminatedOthers',
    TerminatedPrivateConsent = 'TerminatedPrivateConsent',
    TerminatedJapaneseGovernmentScholarshipStudent = 'TerminatedJapaneseGovernmentScholarshipStudent',
    Old = 'Old'
}

export function AuditLogFromJSON(json: any): AuditLog {
    return AuditLogFromJSONTyped(json, false);
}

export function AuditLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'requestTo': !exists(json, 'requestTo') ? undefined : json['requestTo'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'onlineMeeting': !exists(json, 'onlineMeeting') ? undefined : json['onlineMeeting'],
        'nameJa': json['nameJa'],
    };
}

export function AuditLogToJSON(value?: AuditLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'applicationId': value.applicationId,
        'state': value.state,
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'requestTo': value.requestTo,
        'subject': value.subject,
        'body': value.body,
        'accepted': value.accepted,
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'onlineMeeting': value.onlineMeeting,
        'nameJa': value.nameJa,
    };
}


