/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Course,
    CourseFromJSON,
    CourseFromJSONTyped,
    CourseToJSON,
    Teacher,
    TeacherFromJSON,
    TeacherFromJSONTyped,
    TeacherToJSON,
} from './';

/**
 * 
 * @export
 * @interface AcceptanceRecipient
 */
export interface AcceptanceRecipient {
    /**
     * 
     * @type {Date}
     * @memberof AcceptanceRecipient
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof AcceptanceRecipient
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof AcceptanceRecipient
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceRecipient
     */
    type: AcceptanceRecipientTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof AcceptanceRecipient
     */
    expiredAt: Date;
    /**
     * 
     * @type {Teacher}
     * @memberof AcceptanceRecipient
     */
    teacher: Teacher;
    /**
     * 
     * @type {Course}
     * @memberof AcceptanceRecipient
     */
    course?: Course;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptanceRecipient
     */
    observer: boolean;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceRecipient
     */
    token: string;
}

/**
* @export
* @enum {string}
*/
export enum AcceptanceRecipientTypeEnum {
    Main = 'Main',
    Observer = 'Observer'
}

export function AcceptanceRecipientFromJSON(json: any): AcceptanceRecipient {
    return AcceptanceRecipientFromJSONTyped(json, false);
}

export function AcceptanceRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptanceRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'type': json['type'],
        'expiredAt': (new Date(json['expiredAt'])),
        'teacher': TeacherFromJSON(json['teacher']),
        'course': !exists(json, 'course') ? undefined : CourseFromJSON(json['course']),
        'observer': json['observer'],
        'token': json['token'],
    };
}

export function AcceptanceRecipientToJSON(value?: AcceptanceRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'type': value.type,
        'expiredAt': (value.expiredAt.toISOString()),
        'teacher': TeacherToJSON(value.teacher),
        'course': CourseToJSON(value.course),
        'observer': value.observer,
        'token': value.token,
    };
}


