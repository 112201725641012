/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    employeeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    state: UserStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}/**
* @export
* @enum {string}
*/
export enum UserStateEnum {
    Active = 'active',
    Hidden = 'hidden'
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
        'role': json['role'],
        'account': json['account'],
        'email': json['email'],
        'name': json['name'],
        'employeeNumber': !exists(json, 'employeeNumber') ? undefined : json['employeeNumber'],
        'state': json['state'],
        'active': json['active'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'role': value.role,
        'account': value.account,
        'email': value.email,
        'name': value.name,
        'employeeNumber': value.employeeNumber,
        'state': value.state,
        'active': value.active,
    };
}


