/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationSummary
 */
export interface ApplicationSummary {
    /**
     * 
     * @type {number}
     * @memberof ApplicationSummary
     */
    binderId: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSummary
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    firstSentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    sentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    repliedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    familyNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    middleNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    givenNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    university?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    programGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    supervisor: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    asObserver: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    asAcceptanceObserver: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    relayRequestedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    relayDeadline?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    teacherRequested: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummary
     */
    teacherDeadline?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    teacherAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    relayCancelled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    relayReturned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    approvalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummary
     */
    state: ApplicationSummaryStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    reentry: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    resubmit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    duplicated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    archive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSummary
     */
    hidden: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationSummaryStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    PrivateConsentAcceptedForMaster = 'PrivateConsentAcceptedForMaster',
    PrivateConsentAcceptedForBachelor = 'PrivateConsentAcceptedForBachelor',
    PrivateConsentRejected = 'PrivateConsentRejected',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    ApprovalNumberNotifiedForBachelor = 'ApprovalNumberNotifiedForBachelor',
    ApprovalNumberNotifiedForMaster = 'ApprovalNumberNotifiedForMaster',
    ApprovalNumberNotifiedRejected = 'ApprovalNumberNotifiedRejected',
    TerminatedOthers = 'TerminatedOthers',
    TerminatedPrivateConsent = 'TerminatedPrivateConsent',
    TerminatedJapaneseGovernmentScholarshipStudent = 'TerminatedJapaneseGovernmentScholarshipStudent',
    Old = 'Old'
}

export function ApplicationSummaryFromJSON(json: any): ApplicationSummary {
    return ApplicationSummaryFromJSONTyped(json, false);
}

export function ApplicationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binderId': json['binderId'],
        'id': json['id'],
        'firstSentAt': !exists(json, 'firstSentAt') ? undefined : (new Date(json['firstSentAt'])),
        'sentAt': !exists(json, 'sentAt') ? undefined : (new Date(json['sentAt'])),
        'repliedAt': !exists(json, 'repliedAt') ? undefined : (new Date(json['repliedAt'])),
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'givenName': json['givenName'],
        'familyNameInChinese': !exists(json, 'familyNameInChinese') ? undefined : json['familyNameInChinese'],
        'middleNameInChinese': !exists(json, 'middleNameInChinese') ? undefined : json['middleNameInChinese'],
        'givenNameInChinese': !exists(json, 'givenNameInChinese') ? undefined : json['givenNameInChinese'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'university': !exists(json, 'university') ? undefined : json['university'],
        'programGroup': !exists(json, 'programGroup') ? undefined : json['programGroup'],
        'courseName': !exists(json, 'courseName') ? undefined : json['courseName'],
        'supervisor': json['supervisor'],
        'asObserver': json['asObserver'],
        'asAcceptanceObserver': json['asAcceptanceObserver'],
        'relayRequestedAt': !exists(json, 'relayRequestedAt') ? undefined : (new Date(json['relayRequestedAt'])),
        'relayDeadline': !exists(json, 'relayDeadline') ? undefined : (new Date(json['relayDeadline'])),
        'teacherRequested': json['teacherRequested'],
        'teacherDeadline': !exists(json, 'teacherDeadline') ? undefined : (new Date(json['teacherDeadline'])),
        'teacherAccepted': !exists(json, 'teacherAccepted') ? undefined : json['teacherAccepted'],
        'relayCancelled': json['relayCancelled'],
        'relayReturned': json['relayReturned'],
        'approvalNumber': !exists(json, 'approvalNumber') ? undefined : json['approvalNumber'],
        'state': json['state'],
        'reentry': json['reentry'],
        'resubmit': json['resubmit'],
        'duplicated': json['duplicated'],
        'archive': json['archive'],
        'hidden': json['hidden'],
    };
}

export function ApplicationSummaryToJSON(value?: ApplicationSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binderId': value.binderId,
        'id': value.id,
        'firstSentAt': value.firstSentAt === undefined ? undefined : (value.firstSentAt.toISOString()),
        'sentAt': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'repliedAt': value.repliedAt === undefined ? undefined : (value.repliedAt.toISOString()),
        'familyName': value.familyName,
        'middleName': value.middleName,
        'givenName': value.givenName,
        'familyNameInChinese': value.familyNameInChinese,
        'middleNameInChinese': value.middleNameInChinese,
        'givenNameInChinese': value.givenNameInChinese,
        'countryName': value.countryName,
        'university': value.university,
        'programGroup': value.programGroup,
        'courseName': value.courseName,
        'supervisor': value.supervisor,
        'asObserver': value.asObserver,
        'asAcceptanceObserver': value.asAcceptanceObserver,
        'relayRequestedAt': value.relayRequestedAt === undefined ? undefined : (value.relayRequestedAt.toISOString()),
        'relayDeadline': value.relayDeadline === undefined ? undefined : (value.relayDeadline.toISOString()),
        'teacherRequested': value.teacherRequested,
        'teacherDeadline': value.teacherDeadline === undefined ? undefined : (value.teacherDeadline.toISOString()),
        'teacherAccepted': value.teacherAccepted,
        'relayCancelled': value.relayCancelled,
        'relayReturned': value.relayReturned,
        'approvalNumber': value.approvalNumber,
        'state': value.state,
        'reentry': value.reentry,
        'resubmit': value.resubmit,
        'duplicated': value.duplicated,
        'archive': value.archive,
        'hidden': value.hidden,
    };
}


