/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrivateConsentForceFixRequest
 */
export interface PrivateConsentForceFixRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentForceFixRequest
     */
    privateConsentTypeForForceFix: PrivateConsentForceFixRequestPrivateConsentTypeForForceFixEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentForceFixRequest
     */
    reason: string;
}

/**
* @export
* @enum {string}
*/

export enum PrivateConsentForceFixRequestPrivateConsentTypeForForceFixEnum {
    ForBachelor = 'ForBachelor',
    ForMaster = 'ForMaster',
    Rejected = 'Rejected'
}

export function PrivateConsentForceFixRequestFromJSON(json: any): PrivateConsentForceFixRequest {
    return PrivateConsentForceFixRequestFromJSONTyped(json, false);
}

export function PrivateConsentForceFixRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrivateConsentForceFixRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'privateConsentTypeForForceFix': json['privateConsentTypeForForceFix'],
        'reason': json['reason'],
    };
}

export function PrivateConsentForceFixRequestToJSON(value?: PrivateConsentForceFixRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'privateConsentTypeForForceFix': value.privateConsentTypeForForceFix,
        'reason': value.reason,
    };
}


