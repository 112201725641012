/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicaitonTerminateRequest
 */
export interface ApplicaitonTerminateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicaitonTerminateRequest
     */
    terminateReasonType: ApplicaitonTerminateRequestTerminateReasonTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApplicaitonTerminateRequestTerminateReasonTypeEnum {
    Others = 'Others',
    PrivateConsent = 'PrivateConsent',
    JapaneseGovernmentScholarshipStudent = 'JapaneseGovernmentScholarshipStudent'
}

export function ApplicaitonTerminateRequestFromJSON(json: any): ApplicaitonTerminateRequest {
    return ApplicaitonTerminateRequestFromJSONTyped(json, false);
}

export function ApplicaitonTerminateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicaitonTerminateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terminateReasonType': json['terminateReasonType'],
    };
}

export function ApplicaitonTerminateRequestToJSON(value?: ApplicaitonTerminateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terminateReasonType': value.terminateReasonType,
    };
}


