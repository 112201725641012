/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface BulkUploadMaterialResponse
 */
export interface BulkUploadMaterialResponse {
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof BulkUploadMaterialResponse
     */
    attachments?: Array<Attachment>;
}

export function BulkUploadMaterialResponseFromJSON(json: any): BulkUploadMaterialResponse {
    return BulkUploadMaterialResponseFromJSONTyped(json, false);
}

export function BulkUploadMaterialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUploadMaterialResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
    };
}

export function BulkUploadMaterialResponseToJSON(value?: BulkUploadMaterialResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
    };
}


