/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    BulkUploadMaterialResponse,
    BulkUploadMaterialResponseFromJSON,
    BulkUploadMaterialResponseFromJSONTyped,
    BulkUploadMaterialResponseToJSON,
    EducationResponse,
    EducationResponseFromJSON,
    EducationResponseFromJSONTyped,
    EducationResponseToJSON,
    EmploymentHistoryResponse,
    EmploymentHistoryResponseFromJSON,
    EmploymentHistoryResponseFromJSONTyped,
    EmploymentHistoryResponseToJSON,
    Option,
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
    ResponseToApplicant,
    ResponseToApplicantFromJSON,
    ResponseToApplicantFromJSONTyped,
    ResponseToApplicantToJSON,
    ScholarshipResponse,
    ScholarshipResponseFromJSON,
    ScholarshipResponseFromJSONTyped,
    ScholarshipResponseToJSON,
    SupplementalMaterialResponse,
    SupplementalMaterialResponseFromJSON,
    SupplementalMaterialResponseFromJSONTyped,
    SupplementalMaterialResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    applicantId: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    binderId: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    state: ApplicationResponseStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    reentry: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    resubmit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    duplicated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    sentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    firstSentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    repliedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    aaoNote?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    idCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    familyNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    middleNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    givenNameInChinese?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    gender?: ApplicationResponseGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    birthdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    otherNationality?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    primaryPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    secondaryPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    currentAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    currentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    supervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    researchTopic?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    otherProgram?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    anticipatedEnrollmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    employmentHistoryQuestion?: ApplicationResponseEmploymentHistoryQuestionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    language?: ApplicationResponseLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    approvalNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    issueApprovalNumberAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    notifyApprovalNumberBody?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    notifyApprovalNumberEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    notifyApprovalNumberTitle?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    terminatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    terminateReasonType?: ApplicationResponseTerminateReasonTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {ResponseToApplicant}
     * @memberof ApplicationResponse
     */
    acceptResponse?: ResponseToApplicant;
    /**
     * 
     * @type {ResponseToApplicant}
     * @memberof ApplicationResponse
     */
    returnResponse?: ResponseToApplicant;
    /**
     * 
     * @type {ResponseToApplicant}
     * @memberof ApplicationResponse
     */
    notifyApprovalNumberResponse?: ResponseToApplicant;
    /**
     * 
     * @type {Array<EducationResponse>}
     * @memberof ApplicationResponse
     */
    educations: Array<EducationResponse>;
    /**
     * 
     * @type {Array<ScholarshipResponse>}
     * @memberof ApplicationResponse
     */
    scholarships: Array<ScholarshipResponse>;
    /**
     * 
     * @type {Array<SupplementalMaterialResponse>}
     * @memberof ApplicationResponse
     */
    supplementalMaterials: Array<SupplementalMaterialResponse>;
    /**
     * 
     * @type {Array<BulkUploadMaterialResponse>}
     * @memberof ApplicationResponse
     */
    bulkUploadMaterials: Array<BulkUploadMaterialResponse>;
    /**
     * 
     * @type {Array<EmploymentHistoryResponse>}
     * @memberof ApplicationResponse
     */
    employmentHistories: Array<EmploymentHistoryResponse>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ApplicationResponse
     */
    recommendationLetters: Array<Attachment>;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    photo?: Attachment;
    /**
     * 
     * @type {Attachment}
     * @memberof ApplicationResponse
     */
    statementOfPurpose?: Attachment;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    course?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    program?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    nationality?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    secondNationality?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    financialSupport?: Option;
    /**
     * 
     * @type {Option}
     * @memberof ApplicationResponse
     */
    anticipatedEnrollmentTerm?: Option;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationResponseStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    PrivateConsentAcceptedForMaster = 'PrivateConsentAcceptedForMaster',
    PrivateConsentAcceptedForBachelor = 'PrivateConsentAcceptedForBachelor',
    PrivateConsentRejected = 'PrivateConsentRejected',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    ApprovalNumberNotifiedForBachelor = 'ApprovalNumberNotifiedForBachelor',
    ApprovalNumberNotifiedForMaster = 'ApprovalNumberNotifiedForMaster',
    ApprovalNumberNotifiedRejected = 'ApprovalNumberNotifiedRejected',
    TerminatedOthers = 'TerminatedOthers',
    TerminatedPrivateConsent = 'TerminatedPrivateConsent',
    TerminatedJapaneseGovernmentScholarshipStudent = 'TerminatedJapaneseGovernmentScholarshipStudent',
    Old = 'Old'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseGenderEnum {
    Male = 'Male',
    Female = 'Female'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseEmploymentHistoryQuestionEnum {
    Yes = 'yes',
    No = 'no'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseLanguageEnum {
    Ja = 'Ja',
    En = 'En'
}/**
* @export
* @enum {string}
*/
export enum ApplicationResponseTerminateReasonTypeEnum {
    Others = 'Others',
    PrivateConsent = 'PrivateConsent',
    JapaneseGovernmentScholarshipStudent = 'JapaneseGovernmentScholarshipStudent'
}

export function ApplicationResponseFromJSON(json: any): ApplicationResponse {
    return ApplicationResponseFromJSONTyped(json, false);
}

export function ApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'binderId': json['binderId'],
        'state': json['state'],
        'reentry': json['reentry'],
        'resubmit': json['resubmit'],
        'duplicated': !exists(json, 'duplicated') ? undefined : json['duplicated'],
        'sentAt': !exists(json, 'sentAt') ? undefined : (new Date(json['sentAt'])),
        'firstSentAt': !exists(json, 'firstSentAt') ? undefined : (new Date(json['firstSentAt'])),
        'repliedAt': !exists(json, 'repliedAt') ? undefined : (new Date(json['repliedAt'])),
        'aaoNote': !exists(json, 'aaoNote') ? undefined : json['aaoNote'],
        'idCardNumber': !exists(json, 'idCardNumber') ? undefined : json['idCardNumber'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyNameInChinese': !exists(json, 'familyNameInChinese') ? undefined : json['familyNameInChinese'],
        'middleNameInChinese': !exists(json, 'middleNameInChinese') ? undefined : json['middleNameInChinese'],
        'givenNameInChinese': !exists(json, 'givenNameInChinese') ? undefined : json['givenNameInChinese'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthdate': !exists(json, 'birthdate') ? undefined : (new Date(json['birthdate'])),
        'otherNationality': !exists(json, 'otherNationality') ? undefined : json['otherNationality'],
        'primaryPhoneNumber': !exists(json, 'primaryPhoneNumber') ? undefined : json['primaryPhoneNumber'],
        'secondaryPhoneNumber': !exists(json, 'secondaryPhoneNumber') ? undefined : json['secondaryPhoneNumber'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'currentAddress': !exists(json, 'currentAddress') ? undefined : json['currentAddress'],
        'currentStatus': !exists(json, 'currentStatus') ? undefined : json['currentStatus'],
        'supervisor': !exists(json, 'supervisor') ? undefined : json['supervisor'],
        'researchTopic': !exists(json, 'researchTopic') ? undefined : json['researchTopic'],
        'otherProgram': !exists(json, 'otherProgram') ? undefined : json['otherProgram'],
        'anticipatedEnrollmentDate': !exists(json, 'anticipatedEnrollmentDate') ? undefined : (new Date(json['anticipatedEnrollmentDate'])),
        'employmentHistoryQuestion': !exists(json, 'employmentHistoryQuestion') ? undefined : json['employmentHistoryQuestion'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'approvalNumber': !exists(json, 'approvalNumber') ? undefined : json['approvalNumber'],
        'issueApprovalNumberAt': !exists(json, 'issueApprovalNumberAt') ? undefined : (new Date(json['issueApprovalNumberAt'])),
        'notifyApprovalNumberBody': !exists(json, 'notifyApprovalNumberBody') ? undefined : json['notifyApprovalNumberBody'],
        'notifyApprovalNumberEmailAddress': !exists(json, 'notifyApprovalNumberEmailAddress') ? undefined : json['notifyApprovalNumberEmailAddress'],
        'notifyApprovalNumberTitle': !exists(json, 'notifyApprovalNumberTitle') ? undefined : json['notifyApprovalNumberTitle'],
        'terminatedAt': !exists(json, 'terminatedAt') ? undefined : (new Date(json['terminatedAt'])),
        'terminateReasonType': !exists(json, 'terminateReasonType') ? undefined : json['terminateReasonType'],
        'version': json['version'],
        'createdAt': (new Date(json['createdAt'])),
        'acceptResponse': !exists(json, 'acceptResponse') ? undefined : ResponseToApplicantFromJSON(json['acceptResponse']),
        'returnResponse': !exists(json, 'returnResponse') ? undefined : ResponseToApplicantFromJSON(json['returnResponse']),
        'notifyApprovalNumberResponse': !exists(json, 'notifyApprovalNumberResponse') ? undefined : ResponseToApplicantFromJSON(json['notifyApprovalNumberResponse']),
        'educations': ((json['educations'] as Array<any>).map(EducationResponseFromJSON)),
        'scholarships': ((json['scholarships'] as Array<any>).map(ScholarshipResponseFromJSON)),
        'supplementalMaterials': ((json['supplementalMaterials'] as Array<any>).map(SupplementalMaterialResponseFromJSON)),
        'bulkUploadMaterials': ((json['bulkUploadMaterials'] as Array<any>).map(BulkUploadMaterialResponseFromJSON)),
        'employmentHistories': ((json['employmentHistories'] as Array<any>).map(EmploymentHistoryResponseFromJSON)),
        'recommendationLetters': ((json['recommendationLetters'] as Array<any>).map(AttachmentFromJSON)),
        'photo': !exists(json, 'photo') ? undefined : AttachmentFromJSON(json['photo']),
        'statementOfPurpose': !exists(json, 'statementOfPurpose') ? undefined : AttachmentFromJSON(json['statementOfPurpose']),
        'course': !exists(json, 'course') ? undefined : OptionFromJSON(json['course']),
        'program': !exists(json, 'program') ? undefined : OptionFromJSON(json['program']),
        'nationality': !exists(json, 'nationality') ? undefined : OptionFromJSON(json['nationality']),
        'secondNationality': !exists(json, 'secondNationality') ? undefined : OptionFromJSON(json['secondNationality']),
        'financialSupport': !exists(json, 'financialSupport') ? undefined : OptionFromJSON(json['financialSupport']),
        'anticipatedEnrollmentTerm': !exists(json, 'anticipatedEnrollmentTerm') ? undefined : OptionFromJSON(json['anticipatedEnrollmentTerm']),
    };
}

export function ApplicationResponseToJSON(value?: ApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicantId': value.applicantId,
        'binderId': value.binderId,
        'state': value.state,
        'reentry': value.reentry,
        'resubmit': value.resubmit,
        'duplicated': value.duplicated,
        'sentAt': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'firstSentAt': value.firstSentAt === undefined ? undefined : (value.firstSentAt.toISOString()),
        'repliedAt': value.repliedAt === undefined ? undefined : (value.repliedAt.toISOString()),
        'aaoNote': value.aaoNote,
        'idCardNumber': value.idCardNumber,
        'familyName': value.familyName,
        'middleName': value.middleName,
        'givenName': value.givenName,
        'familyNameInChinese': value.familyNameInChinese,
        'middleNameInChinese': value.middleNameInChinese,
        'givenNameInChinese': value.givenNameInChinese,
        'gender': value.gender,
        'birthdate': value.birthdate === undefined ? undefined : (value.birthdate.toISOString()),
        'otherNationality': value.otherNationality,
        'primaryPhoneNumber': value.primaryPhoneNumber,
        'secondaryPhoneNumber': value.secondaryPhoneNumber,
        'postalCode': value.postalCode,
        'currentAddress': value.currentAddress,
        'currentStatus': value.currentStatus,
        'supervisor': value.supervisor,
        'researchTopic': value.researchTopic,
        'otherProgram': value.otherProgram,
        'anticipatedEnrollmentDate': value.anticipatedEnrollmentDate === undefined ? undefined : (value.anticipatedEnrollmentDate.toISOString()),
        'employmentHistoryQuestion': value.employmentHistoryQuestion,
        'language': value.language,
        'notes': value.notes,
        'approvalNumber': value.approvalNumber,
        'issueApprovalNumberAt': value.issueApprovalNumberAt === undefined ? undefined : (value.issueApprovalNumberAt.toISOString()),
        'notifyApprovalNumberBody': value.notifyApprovalNumberBody,
        'notifyApprovalNumberEmailAddress': value.notifyApprovalNumberEmailAddress,
        'notifyApprovalNumberTitle': value.notifyApprovalNumberTitle,
        'terminatedAt': value.terminatedAt === undefined ? undefined : (value.terminatedAt.toISOString()),
        'terminateReasonType': value.terminateReasonType,
        'version': value.version,
        'createdAt': (value.createdAt.toISOString()),
        'acceptResponse': ResponseToApplicantToJSON(value.acceptResponse),
        'returnResponse': ResponseToApplicantToJSON(value.returnResponse),
        'notifyApprovalNumberResponse': ResponseToApplicantToJSON(value.notifyApprovalNumberResponse),
        'educations': ((value.educations as Array<any>).map(EducationResponseToJSON)),
        'scholarships': ((value.scholarships as Array<any>).map(ScholarshipResponseToJSON)),
        'supplementalMaterials': ((value.supplementalMaterials as Array<any>).map(SupplementalMaterialResponseToJSON)),
        'bulkUploadMaterials': ((value.bulkUploadMaterials as Array<any>).map(BulkUploadMaterialResponseToJSON)),
        'employmentHistories': ((value.employmentHistories as Array<any>).map(EmploymentHistoryResponseToJSON)),
        'recommendationLetters': ((value.recommendationLetters as Array<any>).map(AttachmentToJSON)),
        'photo': AttachmentToJSON(value.photo),
        'statementOfPurpose': AttachmentToJSON(value.statementOfPurpose),
        'course': OptionToJSON(value.course),
        'program': OptionToJSON(value.program),
        'nationality': OptionToJSON(value.nationality),
        'secondNationality': OptionToJSON(value.secondNationality),
        'financialSupport': OptionToJSON(value.financialSupport),
        'anticipatedEnrollmentTerm': OptionToJSON(value.anticipatedEnrollmentTerm),
    };
}


