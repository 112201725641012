/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponseToApplicant
 */
export interface ResponseToApplicant {
    /**
     * 
     * @type {Date}
     * @memberof ResponseToApplicant
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseToApplicant
     */
    toState: ResponseToApplicantToStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseToApplicant
     */
    templateId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseToApplicant
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseToApplicant
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseToApplicant
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseToApplicant
     */
    signature: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ResponseToApplicant
     */
    attachments?: Array<Attachment>;
}

/**
* @export
* @enum {string}
*/
export enum ResponseToApplicantToStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    PrivateConsentAcceptedForMaster = 'PrivateConsentAcceptedForMaster',
    PrivateConsentAcceptedForBachelor = 'PrivateConsentAcceptedForBachelor',
    PrivateConsentRejected = 'PrivateConsentRejected',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    ApprovalNumberNotifiedForBachelor = 'ApprovalNumberNotifiedForBachelor',
    ApprovalNumberNotifiedForMaster = 'ApprovalNumberNotifiedForMaster',
    ApprovalNumberNotifiedRejected = 'ApprovalNumberNotifiedRejected',
    TerminatedOthers = 'TerminatedOthers',
    TerminatedPrivateConsent = 'TerminatedPrivateConsent',
    TerminatedJapaneseGovernmentScholarshipStudent = 'TerminatedJapaneseGovernmentScholarshipStudent',
    Old = 'Old'
}

export function ResponseToApplicantFromJSON(json: any): ResponseToApplicant {
    return ResponseToApplicantFromJSONTyped(json, false);
}

export function ResponseToApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseToApplicant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'toState': json['toState'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'subject': json['subject'],
        'body': json['body'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'signature': json['signature'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
    };
}

export function ResponseToApplicantToJSON(value?: ResponseToApplicant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'toState': value.toState,
        'templateId': value.templateId,
        'subject': value.subject,
        'body': value.body,
        'email': value.email,
        'signature': value.signature,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
    };
}


