/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrivateConsentDetail
 */
export interface PrivateConsentDetail {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentDetail
     */
    type?: PrivateConsentDetailTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentDetail
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentDetail
     */
    updatedPrivateConsentType?: PrivateConsentDetailUpdatedPrivateConsentTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PrivateConsentDetail
     */
    privateConsentStateUpdatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentDetail
     */
    privateConsentUpdatedReason?: string;
}

/**
* @export
* @enum {string}
*/
export enum PrivateConsentDetailTypeEnum {
    ForBachelor = 'ForBachelor',
    ForMaster = 'ForMaster',
    Rejected = 'Rejected'
}/**
* @export
* @enum {string}
*/
export enum PrivateConsentDetailUpdatedPrivateConsentTypeEnum {
    ForBachelor = 'ForBachelor',
    ForMaster = 'ForMaster',
    Rejected = 'Rejected'
}

export function PrivateConsentDetailFromJSON(json: any): PrivateConsentDetail {
    return PrivateConsentDetailFromJSONTyped(json, false);
}

export function PrivateConsentDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrivateConsentDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'updatedPrivateConsentType': !exists(json, 'updatedPrivateConsentType') ? undefined : json['updatedPrivateConsentType'],
        'privateConsentStateUpdatedAt': !exists(json, 'privateConsentStateUpdatedAt') ? undefined : (new Date(json['privateConsentStateUpdatedAt'])),
        'privateConsentUpdatedReason': !exists(json, 'privateConsentUpdatedReason') ? undefined : json['privateConsentUpdatedReason'],
    };
}

export function PrivateConsentDetailToJSON(value?: PrivateConsentDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'message': value.message,
        'updatedPrivateConsentType': value.updatedPrivateConsentType,
        'privateConsentStateUpdatedAt': value.privateConsentStateUpdatedAt === undefined ? undefined : (value.privateConsentStateUpdatedAt.toISOString()),
        'privateConsentUpdatedReason': value.privateConsentUpdatedReason,
    };
}


