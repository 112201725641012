/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupCount
 */
export interface GroupCount {
    /**
     * 
     * @type {number}
     * @memberof GroupCount
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GroupCount
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupCount
     */
    states: Array<GroupCountStatesEnum>;
    /**
     * 
     * @type {number}
     * @memberof GroupCount
     */
    count?: number;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof GroupCount
     */
    children: Array<GroupCount>;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    reentry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    resubmit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    all: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCount
     */
    initial: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GroupCountStatesEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    PrivateConsentAcceptedForMaster = 'PrivateConsentAcceptedForMaster',
    PrivateConsentAcceptedForBachelor = 'PrivateConsentAcceptedForBachelor',
    PrivateConsentRejected = 'PrivateConsentRejected',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    ApprovalNumberNotifiedForBachelor = 'ApprovalNumberNotifiedForBachelor',
    ApprovalNumberNotifiedForMaster = 'ApprovalNumberNotifiedForMaster',
    ApprovalNumberNotifiedRejected = 'ApprovalNumberNotifiedRejected',
    TerminatedOthers = 'TerminatedOthers',
    TerminatedPrivateConsent = 'TerminatedPrivateConsent',
    TerminatedJapaneseGovernmentScholarshipStudent = 'TerminatedJapaneseGovernmentScholarshipStudent',
    Old = 'Old'
}

export function GroupCountFromJSON(json: any): GroupCount {
    return GroupCountFromJSONTyped(json, false);
}

export function GroupCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'states': json['states'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'children': ((json['children'] as Array<any>).map(GroupCountFromJSON)),
        'reentry': !exists(json, 'reentry') ? undefined : json['reentry'],
        'resubmit': !exists(json, 'resubmit') ? undefined : json['resubmit'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'all': json['all'],
        'initial': json['initial'],
    };
}

export function GroupCountToJSON(value?: GroupCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'states': value.states,
        'count': value.count,
        'children': ((value.children as Array<any>).map(GroupCountToJSON)),
        'reentry': value.reentry,
        'resubmit': value.resubmit,
        'approved': value.approved,
        'hidden': value.hidden,
        'all': value.all,
        'initial': value.initial,
    };
}


