/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrivateConsentRequest
 */
export interface PrivateConsentRequest {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentRequest
     */
    privateConsentType: PrivateConsentRequestPrivateConsentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsentRequest
     */
    message: string;
}

/**
* @export
* @enum {string}
*/
export enum PrivateConsentRequestPrivateConsentTypeEnum {
    ForBachelor = 'ForBachelor',
    ForMaster = 'ForMaster',
    Rejected = 'Rejected'
}

export function PrivateConsentRequestFromJSON(json: any): PrivateConsentRequest {
    return PrivateConsentRequestFromJSONTyped(json, false);
}

export function PrivateConsentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrivateConsentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'privateConsentType': json['privateConsentType'],
        'message': json['message'],
    };
}

export function PrivateConsentRequestToJSON(value?: PrivateConsentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'privateConsentType': value.privateConsentType,
        'message': value.message,
    };
}


